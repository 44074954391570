var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "journal-card" },
    [
      !_vm.enableEdit
        ? _c(
            "v-expansion-panel",
            {
              staticClass: "elevation-0",
              attrs: { row: "", value: _vm.panel }
            },
            [
              _c(
                "v-expansion-panel-content",
                {
                  attrs: { readonly: "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "actions",
                        fn: function() {
                          return [
                            _c(
                              "v-btn",
                              {
                                staticClass: "ma-0",
                                attrs: { flat: "", icon: "" },
                                on: { click: _vm.togglePanel }
                              },
                              [
                                _c("v-icon", { attrs: { medium: "" } }, [
                                  _vm._v("$vuetify.icons.expand")
                                ])
                              ],
                              1
                            )
                          ]
                        },
                        proxy: true
                      },
                      {
                        key: "header",
                        fn: function() {
                          return [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "expansion-header-title pa-0 pt-2 row ml-2",
                                on: { click: _vm.togglePanel }
                              },
                              [
                                _c(
                                  "h3",
                                  {
                                    staticClass:
                                      "starling-body journal_title ml-0"
                                  },
                                  [
                                    _c(
                                      "v-icon",
                                      {
                                        staticClass:
                                          "primary--text journal-v-icon",
                                        attrs: { "x-large": "" }
                                      },
                                      [
                                        _vm._v(
                                          "$vuetify.icons.starling_journal"
                                        )
                                      ]
                                    ),
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "starling-body journal_title"
                                      },
                                      [_vm._v(_vm._s(_vm.journal.title))]
                                    )
                                  ],
                                  1
                                )
                              ]
                            ),
                            _c("journal-card-header", {
                              staticClass: "expansion-header-options",
                              attrs: {
                                journal: _vm.journal,
                                toggleModal: _vm.toggleModal,
                                activeModalView: _vm.activeModalView
                              }
                            })
                          ]
                        },
                        proxy: true
                      }
                    ],
                    null,
                    false,
                    2192126987
                  )
                },
                [
                  !_vm.embedded &&
                  _vm.journal.therapyTitle &&
                  _vm.journal.sessionTitle
                    ? _c("v-flex", { attrs: { xs12: "", "text-left": "" } }, [
                        _c("p", { staticClass: "starling-text pl-2 pt-1" }, [
                          _c(
                            "a",
                            {
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm.openPreview(_vm.journal.uen)
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm.journal.therapyTitle) +
                                  " > " +
                                  _vm._s(_vm.journal.sessionTitle) +
                                  "\n        "
                              )
                            ]
                          )
                        ])
                      ])
                    : _vm._e(),
                  _c(
                    "v-card",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.modalActive && !_vm.enableEdit,
                          expression: "!modalActive && !enableEdit"
                        }
                      ],
                      staticClass: "journal-card-details"
                    },
                    [
                      _c("journal-card-content", {
                        attrs: { journal: _vm.journal }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.modalActive && !_vm.enableEdit,
                          expression: "modalActive && !enableEdit"
                        }
                      ],
                      staticClass: "expansion-header-title ml-2 mb-2"
                    },
                    [
                      _c("p", { staticClass: "starling-small-text" }, [
                        _vm._v(_vm._s(_vm.formattedDate))
                      ])
                    ]
                  ),
                  _c("journal-card-modal", {
                    staticClass: "scale-in-center",
                    attrs: {
                      journal: _vm.journal,
                      modalActive: _vm.modalActive,
                      activeModalView: _vm.activeModalView,
                      closeModal: _vm.closeModal
                    },
                    on: { edit: _vm.onEdit, delete: _vm.onDelete }
                  })
                ],
                1
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.modalActive && !_vm.enableEdit,
                      expression: "!modalActive && !enableEdit"
                    }
                  ],
                  staticClass: "expansion-header-title ml-2 mb-2 mt-2"
                },
                [
                  _c("p", { staticClass: "starling-small-text" }, [
                    _vm._v(_vm._s(_vm.formattedDate))
                  ])
                ]
              )
            ],
            1
          )
        : _c(
            "div",
            { staticClass: "v-expansion-panel mb-1" },
            [
              _c("journal-form", {
                attrs: { journal: _vm.journal, cancellable: "" },
                on: { update: _vm.onUpdate, cancel: _vm.onCancel }
              })
            ],
            1
          ),
      _c(
        "v-dialog",
        {
          attrs: {
            lazy: "",
            "max-width": "" + (_vm.$vuetify.breakpoint.xl ? "1000px" : "800px"),
            "content-class": "journal-card-preview radius-15"
          },
          on: { input: _vm.handleDialogInput },
          model: {
            value: _vm.showPreview,
            callback: function($$v) {
              _vm.showPreview = $$v
            },
            expression: "showPreview"
          }
        },
        [
          _c(
            "v-card",
            { staticClass: "banner-container", attrs: { flat: "" } },
            [
              _c(
                "div",
                { staticClass: "close-button" },
                [
                  _c(
                    "v-btn",
                    { attrs: { icon: "" }, on: { click: _vm.closePreview } },
                    [_c("v-icon", [_vm._v("close")])],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "px-3" },
                [
                  _vm.previewTherapy
                    ? _c("element-view", {
                        attrs: {
                          therapyGroup: _vm.previewTherapy,
                          isPreview: ""
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }