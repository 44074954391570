var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-menu",
    { attrs: { attach: this.$parent.$el } },
    [
      _c(
        "template",
        { slot: "activator" },
        [
          _c("v-icon", [_vm._v("sort")]),
          _c(
            "span",
            { key: _vm.selected, staticClass: "starling-text no-wrap" },
            [_vm._v(_vm._s(_vm.sortList[_vm.selected]))]
          )
        ],
        1
      ),
      _c(
        "v-list",
        _vm._l(_vm.sortList, function(item, index) {
          return _c(
            "v-list-tile",
            {
              key: index,
              on: {
                click: function($event) {
                  return _vm.menuSelect(item, index)
                }
              }
            },
            [_c("v-list-tile-title", [_vm._v(_vm._s(item))])],
            1
          )
        }),
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }