



























import Vue from 'vue';
import { IJournal } from '@/scripts/store/modules/tools/journals/types';

export default Vue.extend({
  name: 'journal-card-header',
  props: {
    journal: {
      type: Object as () => IJournal,
      required: true,
    },
    toggleModal: {
      type: Function,
      required: true,
    },
    activeModalView: {
      type: String,
      required: false,
    },
  },
  computed: {
    showActionMenu(): boolean {
      return !this.$store.state.journals.isPreviewMode;
    },
  },
});
