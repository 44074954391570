var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "journal-card-header-options" },
    [
      _c(
        "v-menu",
        {
          attrs: {
            bottom: "",
            left: "",
            "content-class": "journal-card-options-menu"
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var on = ref.on
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      {
                        staticClass: "activator-btn ma-0",
                        attrs: { flat: "", icon: "" }
                      },
                      on
                    ),
                    [
                      _c("v-icon", { attrs: { medium: "" } }, [
                        _vm._v("more_vert")
                      ])
                    ],
                    1
                  )
                ]
              }
            }
          ])
        },
        [
          _vm.showActionMenu
            ? _c(
                "v-list",
                { staticClass: "option-list pa-0", attrs: { dense: "" } },
                [
                  _c(
                    "v-list-tile",
                    {
                      on: {
                        click: function($event) {
                          return _vm.toggleModal("edit")
                        }
                      }
                    },
                    [
                      _c(
                        "v-list-tile-action",
                        [
                          _c(
                            "v-icon",
                            {
                              staticClass: "edit-action",
                              attrs: { color: "StarlingDarkblue" }
                            },
                            [_vm._v("edit")]
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-list-tile-title",
                        {
                          staticClass: "starling-explanatory StarlingGrey--text"
                        },
                        [_vm._v(_vm._s(_vm.$t("common.actions.edit")))]
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-list-tile",
                    {
                      on: {
                        click: function($event) {
                          return _vm.toggleModal("delete")
                        }
                      }
                    },
                    [
                      _c(
                        "v-list-tile-action",
                        [
                          _c(
                            "v-icon",
                            {
                              staticClass: "delete-action",
                              attrs: { color: "StarlingDarkblue" }
                            },
                            [_vm._v("delete")]
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-list-tile-title",
                        {
                          staticClass: "starling-explanatory StarlingGrey--text"
                        },
                        [_vm._v(_vm._s(_vm.$t("common.actions.delete")))]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }