





































































import Vue, { VueConstructor } from 'vue';
import { format, parse } from 'date-fns';
import store from '@/scripts/store';
import { IJournal } from '@/scripts/store/modules/tools/journals/types';
import journalCardHeader from '@/views/tools/journal/components/journal-card-header.vue';
import journalCardModal from '@/views/tools/journal/components/card-modal/journal-card-modal.vue';
import JournalCardContent from '@/views/tools/journal/components/journal-card-content.vue';
import journalForm from '@/views/tools/journal/components/journal-form.vue';

const DATE_FORMAT = 'yyyy-MM-dd';

export default Vue.extend({
  name: 'journal-card',
  components: {
    journalCardHeader,
    journalCardModal,
    JournalCardContent,
    journalForm,
    'element-view': (): Promise<VueConstructor> =>
      import('@/views/products/components/element-view.vue')
        .then((m: any) => m.default || m) as Promise<VueConstructor>,
  },
  props: {
    journal: {
      type: Object as () => IJournal,
      required: true,
    },
    embedded: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      activeModalView: undefined as string | undefined,
      modalActive: false,
      enableEdit: false,
      panel: null as number | null,
      dateField: undefined as string | undefined,
      showPreview: false,
      previewTherapy: null,
    };
  },
  computed: {
    currentLanguage() {
      return store.getters.currentLanguage;
    },
    formattedDate: {
      get(): string | null {
        return this.dateField
          ? format(
            parse(this.dateField, DATE_FORMAT, new Date()),
            this.abbreviatedDateFormat,
            { locale: this.currentLocale },
          )
          : null;
      },
    },
    currentLocale() {
      return store.getters.getCurrentLocale;
    },
    abbreviatedDateFormat() {
      return store.getters.getAbbreviatedDateFormat;
    },
  },
  watch: {
    journal: {
      deep: true,
      handler(newJournal) {
        this.dateField = format(
          new Date(newJournal.journalDate || new Date()),
          DATE_FORMAT,
        );
        if (newJournal.journalDate !== this.journal.journalDate) {
          this.$emit('update', newJournal);
        }
      },
    },
  },
  mounted() {
    this.dateField = format(
      new Date(this.journal.journalDate || new Date()),
      DATE_FORMAT,
    );
  },
  methods: {
    togglePanel() {
      if (this.panel === 0) {
        this.panel = null;
        this.closeModal();
      } else {
        this.panel = 0;
      }
    },
    toggleModal(viewName: string) {
      if (viewName === 'edit') {
        this.onEdit();
        return;
      }
      this.panel = 0;
      this.activeModalView = viewName;
      this.modalActive = true;
    },
    closeModal() {
      this.modalActive = false;
      this.activeModalView = undefined;
    },
    onDelete() {
      this.$store.dispatch('journals/deleteJournal', this.journal.id).then(() => {
        this.closeModal();
        this.$emit('delete', undefined);
      }, () => {
        this.$store.commit('setNotification', { text: this.$t('app.tools.goal_setter.notifications.errored') });
      });
    },
    onEdit() {
      this.closeModal();
      this.panel = 0;
      this.enableEdit = true;
    },
    onUpdate(journal: IJournal) {
      this.enableEdit = false;
      this.$emit('update', journal);
    },
    onCancel() {
      this.closeModal();
      this.togglePanel();
      this.enableEdit = false;
    },
    openPreview(uen: string): void {
      this.$store.commit('journals/setPreviewMode', true);
      if (this.previewTherapy) {
        this.showPreview = true;
        return;
      }
      this.$log.debug('Loading therapy for uen ', uen);
      this.$store.commit('setLoadingView', true);
      this.$store
        .dispatch('therapy/getTherapyPreview', uen)
        .then(res => {
          this.previewTherapy = res;
          this.showPreview = true;
        })
        .finally(() => {
          this.$store.commit('setLoadingView', false);
        });
    },
    closePreview() {
      this.showPreview = false;
      this.$store.commit('journals/setPreviewMode', false);
    },
    handleDialogInput(value: boolean) {
      if (!value) {
        this.closePreview();
      }
    },
  },
});
