<template>
<div class="modal-content">
  <p class="starling-explanatory mb-2">{{ $t('app.tools.journal.journal_card.delete') }}</p>
  <action-buttons
          :primary="{label : $t('common.actions.delete'), disabled : false, loading : false}"
          @primary-clicked="actionClick"
          :secondary="{label : $t('common.actions.cancel'),disabled : false, loading : false}"
          @secondary-clicked="closeModal">
  </action-buttons>
</div>
</template>

<script>
import actionButtons from '@/views/components/navigation/action-buttons.vue';

export default {
  name: 'journalCardDelete',
  components: {
    actionButtons,
  },
  props: [ 'journal', 'closeModal' ],
  methods: {
    actionClick() {
      this.$emit('action');
    },
  },
};
</script>
