<template>
<v-menu :attach="this.$parent.$el">
  <template slot="activator">
    <v-icon>sort</v-icon>
    <span class="starling-text no-wrap" :key="selected">{{sortList[selected]}}</span>
  </template>
  <v-list>
    <v-list-tile
            v-for="(item, index) in sortList"
            :key="index"
            @click="menuSelect(item, index)">
      <v-list-tile-title>{{ item }}</v-list-tile-title>
    </v-list-tile>
  </v-list>
</v-menu>
</template>

<script>
export default {
  name: 'sortMenu',
  props: {
    value: {
      type: String,
      required: false,
    },
    sortList: {
      type: Array,
      required: true,
    },
    actionClick: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      selected: 0,
    };
  },
  watch: {
    '$route.query.sli'(sli) {
      // gets Sort List Index from route params
      this.selected = sli;
    },
  },
  mounted() {
    if (this.value) {
      this.selected = this.sortList.indexOf(this.value);
    }
  },
  methods: {
    menuSelect(item, index) {
      this.selected = index;
      this.actionClick(item);
    },
  },
};
</script>
