










import Vue from 'vue';
import { IJournal } from '@/scripts/store/modules/tools/journals/types';
export default Vue.extend({
  name: 'journal-card-content',
  props: {
    journal: {
      type: Object as () => IJournal,
      required: true,
    },
  },
});
