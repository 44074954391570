<template>
<span class="card-modal" :class="{[`modal-active ${activeModalView}`]: modalActive}">
  <v-card-text class="pa-2" >
    <journal-card-edit
            :class="{['content-active']: modalActive}"
            v-if="activeModalView === 'edit'"
            :journal="journal"
            :modalActive="modalActive"
            :closeModal="closeModal"
            @action="onAction">
    </journal-card-edit>
    <journal-card-delete
            :class="{['content-active']: modalActive}"
            v-if="activeModalView === 'delete'"
            :journal="journal"
            :closeModal="closeModal"
            @action="onAction">
    </journal-card-delete>
  </v-card-text>
</span>
</template>

<script>
import journalCardEdit from '@/views/tools/journal/components/card-modal/journal-card-edit.vue';
import journalCardDelete from '@/views/tools/journal/components/card-modal/journal-card-delete.vue';

export default {
  name: 'journalCardModal',
  components: {
    journalCardEdit,
    journalCardDelete,
  },
  props: [ 'journal', 'activeModalView', 'modalActive', 'closeModal' ],
  methods: {
    onAction() {
      this.$emit(this.activeModalView);
    },
  },
};
</script>

<style lang="scss">
.card-modal {
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;

  &.modal-active {
    overflow: auto;
  }
}
</style>
